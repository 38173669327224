import React, { useState, useEffect, useRef, useContext } from 'react'
import styled from 'styled-components'

import { theme } from '../../../../../theme'

import { ShowBarDownArrowIcon } from 'components/common/icons/SvgIcons'

import { TicketContext } from '../ticketContext/TicketContext'

import Ticket from './Ticket'
import Promotion from './Promotion'

const Title = styled.p`
	font-size: ${theme.fontSizes.sm};
	text-transform: uppercase;
`

const Container = styled.div`
	width: 49%;
	position: relative;
`

const Tickets = styled.div`
	margin-top: ${theme.margin.xs};
	margin-bottom: ${theme.margin.xs};
	max-height: 300px;
	overflow-y: scroll;
`

const IconContainer = styled.div`
	display: flex;
	justify-content: center;
	margin: ${theme.margin.xs} 0;
	position: absolute;
	top: ${(props) => (props.isAtTop ? '25px' : 'auto')};
	bottom: ${(props) => (props.isAtBottom ? '7px' : 'auto')};
	z-index: 5;
	width: 100%;
`

const TicketScrollable = ({ title, tickets, isPromoTicket, promos }) => {
	const [hasOverflow, setHasOverflow] = useState(tickets?.length > 5 || false)

	const [isAtTop, setIsAtTop] = useState(false)
	const [isAtBottom, setIsAtBottom] = useState(tickets?.length > 5)

	const ticketContext = useContext(TicketContext)
	const ticketsRef = useRef(null)

	useEffect(() => {
		setHasOverflow(tickets?.length > 5)
		setIsAtBottom(tickets?.length > 5)
	}, [tickets])

	const handleScroll = () => {
		const scrollTop = ticketsRef.current.scrollTop
		const scrollHeight = ticketsRef.current.scrollHeight
		const clientHeight = ticketsRef.current.clientHeight

		setIsAtBottom(scrollTop + clientHeight < scrollHeight)
		setIsAtTop(scrollTop > 0)
	}

	useEffect(() => {
		if (ticketsRef.current) {
			ticketsRef.current.addEventListener('scroll', handleScroll)
		}

		return () => {
			if (ticketsRef.current) {
				ticketsRef.current.removeEventListener('scroll', handleScroll)
			}
		}
	}, [])

	const renderTickets = () => {
		return tickets.map((ticket, index) => {
			let promotions = []
			promos.map((promo) => {
				promo.ticketRules.map((promoDetail) => {
					if (promoDetail.ticketTypeId == ticket.ticketTypeId) {
						let promoTicket = {
							name: promo.promotionName,
							promotionCode: promo.promoCode,
							promotionId: promo.promotionId,
							ticketRuleId: promoDetail.id,
							price: promoDetail.price + promoDetail.facilityFeePrice,
							facilityFeeApplied: promoDetail.facilityFeeName != null && promoDetail.facilityFeePrice > 0 ? true : false,
							facilityFeeName: promoDetail.facilityFeeName,
							facilityFeePrice: promoDetail.facilityFeePrice,
							convenienceFeeBO: promoDetail.consumerFeeBO,
							priceIncludingFees: null,
							type: promo.type,
						}
						promoTicket.priceIncludingFees =
							promoTicket.price +
							promoTicket.convenienceFeeBO +
							promoTicket.facilityFeePrice
						promotions.push(promoTicket)
					}
				})
			})

			ticket.promotion = promotions
			ticket.promotionId = null
			ticket.promotionCode = null
			ticket.selectedTicketRuleId = null

			return (
				<Ticket
					isFirstTicket={!index}
					key={index}
					ticket={ticket}
					isPromoTicket={false}
					resetPromo={resetPromo}
				/>
			)
		})
	}

	const resetPromo = (ticket) => {
		if (ticketContext.selectedPromo?.name === 'Full price') return

		const promoExists = ticket?.promotion?.find(
			(promo) => promo?.name === ticketContext.selectedPromo?.name
		)
		if (!promoExists) {
			ticketContext.setTicketForPricing(ticket)
			ticketContext.setSelectedPromo({
				...ticketContext.selectedTicket?.promotion[0],
				name: 'Full price',
			})
		}
	}

	const renderPromoTickets = () => {
		const selectedTicket = ticketContext.selectedTicket || { promotion: [] }
		const promoTickets = selectedTicket.promotion || []
		if (promoTickets.some(promo => promo.type === 'P')) {
			return promoTickets.map((promo, index) => {
				return (
					<Promotion
						isFirstTicket={!index}
						key={index}
						promo={promo}
						isPromoTicket={true}
						resetPromo={resetPromo}
					/>
				)
			})
		}
		return [
			{ ...tickets[0], name: 'Full price' },
			...promoTickets,
		].map((promo, index) => {
			return (
				<Promotion
					isFirstTicket={!index}
					key={index}
					promo={promo}
					isPromoTicket={true}
					resetPromo={resetPromo}
				/>
			)
		})
	}

	return (
		<Container>
			<Title>{title}</Title>
			{hasOverflow && isAtTop && (
				<IconContainer isAtTop={true} isAtBottom={false}>
					<div style={{ transform: 'rotate(180deg)' }}>
						<ShowBarDownArrowIcon height='8' width='32' />
					</div>
				</IconContainer>
			)}
			<Tickets hasOverflow={hasOverflow} ref={ticketsRef}>
				{isPromoTicket ? renderPromoTickets() : renderTickets()}
			</Tickets>
			{hasOverflow && isAtBottom && (
				<IconContainer isAtTop={false} isAtBottom={true}>
					<div style={{ transform: 'rotate(0deg)' }}>
						<ShowBarDownArrowIcon height='8' width='32' />
					</div>
				</IconContainer>
			)}
		</Container>
	)
}

export default TicketScrollable
