function updateViewPort(scale, centerPoint) {
	const { viewPort, onInitView } = this.props
	viewPort.centerToScale(scale, centerPoint)
	onInitView({
		viewPort,
		viewPortChartCenter: centerPoint,
		scale: {
			...this.props.scale,
			current: scale,
		},
	})
}

function adjustCenterPoint(center) {
	const { viewPortChartCenter } = this.props
	if (Array.isArray(center)) {
		const dX = center[0] - viewPortChartCenter[0]
		const dY = center[1] - viewPortChartCenter[1]
		this.props.onDragSeatingChart(-dX, -dY)
		return center
	}
	return viewPortChartCenter
}

export function zoomHome() {
	const { scale, chart } = this.props
	!!this.Viewer && this.Viewer.fitToViewer()
	this.setState({
		mobileScale: {
			...this.state.mobileScale,
			current: this.state.mobileScale.home,
		},
	})
	this.props.onResetDragSeatingChart()
	updateViewPort.call(this, scale.home, chart.centerPoint)
}

export function calculateScaleStep() {
	const {
		chartSize: [width, height],
	} = this.props
	const largerDimension = Math.max(width, height)
	const SMALL_CHART_THRESHOLD = 700
	const VERY_SMALL_CHART_THRESHOLD = 200

	let baseStep = 0.3 // for larger charts

	if (largerDimension < VERY_SMALL_CHART_THRESHOLD) {
		baseStep = 1 // small charts
	} else if (largerDimension < SMALL_CHART_THRESHOLD) {
		baseStep = 0.5 // medium charts
	}

	const scaleStep = Math.min(baseStep + largerDimension * 0.0001)
	this.setState({ scaleStep })
}

export function zoomIn(center, clickToShowSeats = false) {
	const { scale, hidePopup, isFixedPackageSeating } = this.props
	const { scaleStep } = this.state

	let newScale = scale.current + scaleStep
	if (clickToShowSeats) {
		newScale = Math.min(scale.home + scaleStep, scale.max)
	} else {
		newScale = Math.min(newScale, scale.max)
	}

	const centerPoint = adjustCenterPoint.call(this, center)
	updateViewPort.call(this, newScale, centerPoint)

	if (!isFixedPackageSeating) {
		hidePopup()
	}
}

export function zoomOut(center) {
	const { scale, hidePopup, isFixedPackageSeating } = this.props
	const { scaleStep } = this.state

	let newScale = scale.current - scaleStep
	newScale = Math.max(newScale, scale.min)

	const centerPoint = adjustCenterPoint.call(this, center)
	updateViewPort.call(this, newScale, centerPoint)

	if (!isFixedPackageSeating) {
		hidePopup()
	}
}
