// Libraries
import React from 'react'
import { connect } from 'react-redux'
import { diff } from 'deep-object-diff'
import { UncontrolledReactSVGPanZoom } from 'react-svg-pan-zoom'

// Utils
import { VectorUtils } from 'util/MathFunctions'

import { MouseDragHandler } from 'components/MouseDragHandler'
import { MapToolbar } from 'components/seatingChart/seatSelection/MapToolbar'
import { MapControllers } from 'components/seatingChart/seatSelection/MapControllers'

// scss
import './../../../sass/components/seatingChart/SeatingChart.scss'

// selectors
import { getClientViewportAttributes } from 'components/seatingChart/selectors'

// actions
import {
	zoomScale,
	initView,
	dragSeatingChart,
	resetDragSeatingChart,
} from 'components/seatingChart/actions'

// pan handlers
import {
	onDrag,
	onDragEnd,
	onDragStart,
	onClick,
	onPinch,
} from '../../utils/handlers/panHandlers'

// zoom handlers
import {
	zoomHome,
	calculateScaleStep,
	zoomIn,
	zoomOut,
} from '../../utils/handlers/zoomHandlers'

// utility functions
import {
	getActivePriceLevels,
	getItems,
	createSelectionObject,
	handleFilterChange,
	onSeatSelect,
	onSeatUnselect,
	renderSection,
	renderObject,
	renderObjects,
	getRenderableSections,
} from '../../utils/handlers/seatingChartHandlers'

class SeatingChart extends React.Component {
	chart = React.createRef()
	viewport = React.createRef()
	seatingChart = React.createRef()
	Viewer = null
	state = {
		dragging: false,
		dragState: [],
		priceLevelFilters: [],
		priceRangeFilterApplied: false,
		showPriceLevels: !this.props.stepSelect,
		ticketTypeData: {},
		chartBox: {
			x: 0,
			y: 0,
			width: 0,
			height: 0,
		},
		mobileScale: {
			min: this.props.scale.min,
			max: this.props.scale.max,
			home: this.props.scale.home,
			current: this.props.scale.home,
		},
		mobileCurrentScale: 0,
	}

	// Component lifecycles
	componentDidMount() {
		const {
			chart,
			chartSize,
			viewPort,
			viewPortChartCenter,
			scale,
			pan,
			onInitView,
			isSmallDevice,
		} = this.props

		if (isSmallDevice) {
			this.Viewer.fitToViewer()
			this.setState({
				mobileScale: {
					...this.state.mobileScale,
					current: this.state.mobileScale.home,
				},
			})
		}

		onInitView({ chart, chartSize, scale, pan, viewPort, viewPortChartCenter })

		this.calculateScaleStep()
	}

	componentWillUnmount() {
		const { scale, chart, viewPort, onInitView } = this.props
		this.props.onResetDragSeatingChart()
		viewPort.centerToScale(scale.home, chart.centerPoint)
		onInitView({
			viewPort,
			viewPortChartCenter: chart.centerPoint,
			scale: {
				...scale,
				current: scale.home,
			},
		})
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.chart.current) {
			const chartBox = this.chart.current.getBBox()
			if (
				prevState.chartBox.x !== chartBox.x ||
				prevState.chartBox.y !== chartBox.y ||
				prevState.chartBox.width !== chartBox.width ||
				prevState.chartBox.height !== chartBox.height
			) {
				this.setState({
					chartBox: {
						width: chartBox.width,
						height: chartBox.height,
						x: chartBox.x,
						y: chartBox.y,
					},
				})
			}
		}

		if (
			prevState.chartBox.width !== this.state.chartBox.width ||
			prevState.chartBox.height !== this.state.chartBox.height
		) {
			this.calculateScaleStep()
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		const stateDiff = diff(nextState, this.state)
		if (Object.keys(stateDiff).length !== 0) {
			return true
		}
		return Object.keys(diff(nextProps, this.props)).length !== 0
	}
	// 

	// Event handlers for panning
	dragHandler = new MouseDragHandler({
		preventDefault: false,
		stopPropagation: true,
	})
		.onDrag(onDrag.bind(this))
		.onDragEnd(onDragEnd.bind(this))
		.onDragStart(onDragStart.bind(this))
		.onClick(onClick.bind(this))
		.onPinch(onPinch.bind(this))

	// Zoom methods
	zoomHome = zoomHome.bind(this)
	calculateScaleStep = calculateScaleStep.bind(this)
	zoomIn = zoomIn.bind(this)
	zoomOut = zoomOut.bind(this)

	// Rendering methods
	renderSection = renderSection.bind(this)
	renderObject = renderObject.bind(this)
	renderObjects = renderObjects.bind(this)

	// Other seating chart methods
	getActivePriceLevels = getActivePriceLevels.bind(this)
	getItems = getItems.bind(this)
	createSelectionObject = createSelectionObject.bind(this)
	handleFilterChange = handleFilterChange.bind(this)
	onSeatSelect = onSeatSelect.bind(this)
	onSeatUnselect = onSeatUnselect.bind(this)
	getRenderableSections = getRenderableSections.bind(this)

	renderSVG = () => {
		const { viewPort, viewPortSize, scale } = this.props
		const { animate, dragging } = this.state
		const origin = VectorUtils.scalarMultiply(viewPortSize, 0.5)
		const canMove = viewPort ? viewPort.canMove : false
		const [translateX, translateY] = viewPort ? viewPort.location : [0, 0]

		return (
			<svg
				ref={this.viewport}
				id='viewport'
				width={viewPortSize[0]}
				height={viewPortSize[1]}
				viewBox={`0 0 ${viewPortSize[0]} ${viewPortSize[1]}`}
				className={`chart_svg ${canMove ? 'canMove' : ''} ${dragging ? 'dragging' : ''}`}
				{...this.dragHandler.mouseEvents}
			>
				{viewPort && (
					<g
						id='viewportGroup'
						transform={`translate(${origin[0] || 0}, ${origin[1] || 0})`}
						className={`${animate ? 'animate' : ''}`}
					>
						<g
							ref={this.chart}
							transform={`translate(${translateX || 0}, ${translateY || 0})`}
						>
							<g
								transform={`scale(${scale.current})`}
								className={`chart_group ${animate ? 'animate' : ''}`}
							>
								{this.renderObjects()}
							</g>
						</g>
					</g>
				)}
			</svg>
		)
	}

	render() {
		const {
			className,
			noToolBar,
			isFixedPackageSeating,
			scale,
			viewPort,
			viewPortSize,
			pan,
			isSmallDevice,
			data,
			quantity,
			disableFilter,
			hasAccSeating,
			currencySymbol,
			featureFlags,
			style,
		} = this.props
		const { mobileScale } = this.state

		const activePriceLevels = this.getActivePriceLevels()
		const priceLevels = activePriceLevels
			? this.getItems(activePriceLevels)
			: undefined
		const miniatureProps = { position: 'none' }
		const hasPan = pan && (pan.dX !== 0 || pan.dY !== 0)
		const isTraditionalSeatingChart = data.traditional

		const onZoom = (event) => {
			this.setState({
				mobileScale: {
					...scale,
					current: event.a,
				},
			})
		}
		const sections = this.getRenderableSections().map((section) => section)
		return (
			<div
				className={`ot_seatingChart ${className ? className : ''}`}
				style={style}
				{...this.dragHandler.mouseEvents}
			>
				<MapToolbar
					quantity={quantity}
					showOutline={
						mobileScale.home >= mobileScale.current && isTraditionalSeatingChart
					}
					disableSelect={disableFilter}
					hasAccSeating={hasAccSeating}
					onFilterChange={this.handleFilterChange}
					priceLevels={priceLevels}
					priceLevelFilters={this.state.priceLevelFilters}
					noToolBar={noToolBar}
					isSmallDevice={isSmallDevice}
					isFixedPackageSeating={isFixedPackageSeating}
					currencySymbol={currencySymbol}
					featureFlags={featureFlags}
				/>
				<MapControllers
					quantity={quantity}
					scale={scale}
					hasPan={hasPan}
					pan={pan}
					sections={sections}
					viewPort={viewPort}
					viewPortChartCenter={this.props.viewPortChartCenter}
					dragging={this.state.dragging}
					seatingChart={this.props.seatingChart}
					viewPortSize={viewPortSize}
					chartSize={this.props.chartSize}
					mobileScale={mobileScale}
					noToolBar={noToolBar}
					isFixedPackageSeating={isFixedPackageSeating}
					zoom={{
						onHome: this.zoomHome,
						onZoomIn: this.zoomIn,
						onZoomOut: this.zoomOut,
					}}
				/>
				{isSmallDevice ? (
					<UncontrolledReactSVGPanZoom
						width={viewPortSize[0]}
						height={viewPortSize[1] - 139 - 52}
						background='#ffffff'
						ref={(Viewer) => (this.Viewer = Viewer)}
						scaleFactorMin={scale.min}
						scaleFactorMax={scale.max}
						detectAutoPan={false}
						detectWheel={false}
						miniatureProps={miniatureProps}
						toolbarProps={miniatureProps}
						style={{ marginTop: 52 }}
						tool='auto'
						onZoom={onZoom}
					>
						<svg
							viewBox={`${this.props.chart.minPoint[0]} ${this.props.chart.minPoint[1]} ${this.props.chartSize[0]} ${this.props.chartSize[1]}`}
							ref={this.viewport}
							id='viewport'
							className='chart_svg'
						>
							{viewPort && (
								<g
									id='viewportGroup'
									className={`${this.state.animate ? 'animate' : ''}`}
								>
									<g ref={this.chart}>
										<g
											className={`chart_group ${this.state.animate ? 'animate' : ''}`}
										>
											{this.renderObjects()}
										</g>
									</g>
								</g>
							)}
						</svg>
					</UncontrolledReactSVGPanZoom>
				) : (
					this.renderSVG()
				)}
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	const seatingChart = ownProps.data
	const viewportAttributes = seatingChart
		? getClientViewportAttributes(state, ownProps)
		: {}

	return {
		...ownProps,
		...viewportAttributes,
	}
}

const mapDispatchToProps = (dispatch) => ({
	onInitView: (view) => dispatch(initView(view)),
	onZoomScale: (scale) => dispatch(zoomScale(scale)),
	onDragSeatingChart: (dX, dY) => {
		dispatch(dragSeatingChart(dX, dY))
	},
	onResetDragSeatingChart: () => dispatch(resetDragSeatingChart()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SeatingChart)
