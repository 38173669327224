import { VectorUtils } from '../../../util/MathFunctions'

function updateViewPortAndCenter(props, dX, dY) {
	const viewPortChartCenter = VectorUtils.subtract(
		props.viewPortChartCenter,
		VectorUtils.toVector(dX, dY)
	)

	props.viewPort.centerToScale(props.scale.current, viewPortChartCenter)
	props.onInitView({
		viewPort: props.viewPort,
		viewPortChartCenter,
	})
}

export function onDrag(dragState) {
	const { dX, dY } = dragState
	this.props.onDragSeatingChart(dX, dY)
	this.props.isDragging(true, dX, dY)
	updateViewPortAndCenter(this.props, dX, dY)
	this.setState({ dragState })
}

export function onDragEnd() {
	this.setState({ dragging: false })
	this.props.isDragging(false)
}

export function onDragStart() {
	this.setState({ dragging: true })
	this.props.isDragging(true)
}

export function onClick() {
	if (!this.props.isFixedPackageSeating) {
		this.props.hidePopup()
	}
}

export function onPinch(dragState) {
	const { scale } = this.props
	const newScale = Math.max(
		scale.min,
		Math.min(scale.max, scale.current * dragState.scale)
	)
	const viewPort = this.props.viewPort.centerToScale(
		newScale,
		dragState.currentRectangle.center
	)

	this.setState({
		scale: {
			...this.state.scale,
			current: newScale,
		},
		viewPort,
		viewPortChartCenterPoint: null,
		animate: true,
	})
}
